import { Box, useTheme } from '@mui/material';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { SUPPORT_EMAIL } from '@fdha/common-utils';

import { Button } from '../Button/Button';
import { Typography } from '../Typography/Typography';

export interface ErrorFallbackProps extends FallbackProps {
  title?: { text: string; i18nKey?: string };
  subtitle?: { text: string; i18nKey?: string };
}

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  resetErrorBoundary,
  title,
  subtitle,
}) => {
  const theme = useTheme();

  const defaultTitle = {
    text: 'Oops, something went wrong!',
    i18nKey: 'common:error.title',
  };
  const defaultSubtitle = {
    text: `The app ran into a problem and could not continue.\nPlease reload the page or contact us at ${SUPPORT_EMAIL} if the issue persists.`,
    i18nKey: 'common:error.subtitle',
  };

  const newTitle = title || defaultTitle;
  const newSubtitle = subtitle || defaultSubtitle;

  return (
    <Box width="100%">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding={4}
        textAlign="center"
        sx={{
          backgroundColor: theme.palette.primary.main,
          borderBottomRightRadius: 40,
          borderBottomLeftRadius: 40,
        }}
      >
        <Typography
          variant="h2"
          color={theme.palette.primary.contrastText}
          i18nKey={newTitle.i18nKey}
        >
          {newTitle.text}
        </Typography>
        <Typography
          variant="h5"
          color={theme.palette.primary.contrastText}
          sx={{ pt: 4 }}
          i18nKey={newSubtitle.i18nKey}
        >
          {newSubtitle.text}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mt={8}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          sx={{ px: 10 }}
          onClick={resetErrorBoundary}
          i18nKey="common:button.reload"
        >
          Reload Page
        </Button>
      </Box>
    </Box>
  );
};
